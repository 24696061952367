import React from 'react';
import PropTypes from 'prop-types';
import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PayPalButton } from 'react-paypal-button-v2';
import styled from 'styled-components';

import { getCompanyConfig } from '../../../helpers/common';
import Mixpanel from '../../../analytics/mixpanel';

const StyledDiv = styled.div`
  width: 180px;

  @media (max-width: 750px) {
    width: 200px !important;
  }
`;

const COMPANY_CONFIG = getCompanyConfig();
const CLIENT_ID = COMPANY_CONFIG['payPalClientId'];

export default function MakePaymentWithPayPalButton({ amount = 100, loading, callback }) {
  function onDeposited() {
    notification.success({ message: 'Payment is successful. Please wait 1-3 minutes till the funds will appear in your account balance.' });

    if (callback) {
      callback();
    }
  }

  return <Spin indicator={<LoadingOutlined />} spinning={loading}>
    <StyledDiv>
      <PayPalButton
        options={{ clientId: CLIENT_ID, disableFunding: 'paylater' }}
        style={{
          layout: 'vertical',
          label: 'paypal',
          tagline: false,
          height: 31,
        }}
        createOrder={(data, actions) => {
          Mixpanel.track('Payment intent', { amount, method: 'PayPal' });

          return actions.order.create({
            intent: 'CAPTURE',
            purchase_units: [{
              reference_id: localStorage.getItem('email'),
              description: 'Make a payment to add credits into account balance.',
              amount: { currency_code: 'USD', value: amount }
            }],
            application_context: { shipping_preference: 'NO_SHIPPING' },
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            onDeposited(details.payer);
          });
        }}
      />
    </StyledDiv>
  </Spin>;
}

MakePaymentWithPayPalButton.propTypes = {
  amount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  callback: PropTypes.func,
};
