import { combineReducers } from 'redux';

import profileReducer from './profileReducer';
import paymentReducer from './paymentReducer';
import { itemsReducer } from './itemsReducer';

export default combineReducers({
  profileReducer,
  paymentReducer,
  items: itemsReducer,
});
