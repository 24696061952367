import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PrivateRoute({ component: Component, auth, ...rest }) {
  const { authenticated = false } = auth;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigate(`/login?redirect=${location.pathname}${location.search}`);
    }
  }, [authenticated, location, navigate]);

  return authenticated ? <Component {...rest} /> : null;
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  auth: PropTypes.object.isRequired,
};
