import React, { useEffect, useState } from 'react';
import { Table, Button, notification } from 'antd';
import { ReactCountryFlag } from 'react-country-flag';
import Moment from 'react-moment';

import { toArray } from '../../utils/helper';
import apiProfile from '../../api/apiProfile';
import Categories from '../Common/Categories';
import Address from '../Common/Address';

export default function Checkouts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tasksColumns = [
    {
      title: 'What',
      dataIndex: 'id',
      key: 'id',
      render: (id, { payload }) => <>
        <Categories value={payload.search_params.type ? toArray(payload.search_params.type) : ['all categories']} />
      </>
    },
    {
      title: 'Where',
      dataIndex: 'id',
      key: 'id',
      render: (id, { payload }) => <>
        <div title={payload.search_params.cc}>
          <ReactCountryFlag countryCode={payload.search_params.cc} /> <Address value={payload.search_params} />
        </div>
      </>
    },
    {
      title: 'When',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => <>{created_at && <Moment utc local fromNow>{created_at}</Moment>}</>,
    },
    {
      title: 'Quantity',
      dataIndex: 'id',
      key: 'id',
      render: (id, { payload }) => <>{payload.quantity}</>
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (id, { payload }) =>
        <>
          <a
            key='results'
            target='_blank'
            rel='noreferrer'
            href={payload.success_url}
          >
            <Button
              size='small'
              title={`About ${payload.quantity} rows`}
            >Download Results</Button>
          </a>
          <a
            key='search'
            target='_blank'
            rel='noreferrer'
            href={payload.current_url}
            style={{ paddingLeft: 10 }}
          >
            <Button
              size='small'
              title={`About ${payload.total} rows`}
            >To Search</Button>
          </a>
        </>
      ,
    }
  ];

  useEffect(() => {
    onFetchData();
  }, []);

  async function onFetchData() {
    setLoading(true);
    try {
      const data = await apiProfile.getCheckouts();
      setData(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <>
    <Table
      rowKey={record => `${record.created_at}${record.amount_total}`}
      size='small'
      scroll={{ y: 800 }}
      loading={loading}
      pagination={false}
      columns={tasksColumns}
      dataSource={data}
    />
  </>;
}
