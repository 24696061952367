import { O, T } from './local';

export default {
  auth0ClientID: 'iAlx5e3dspTk5yRzIRROsqKK0iDOTEwh',
  apiDomain: `dahab.app.${O}.com`,
  mixpanelToken: '6d7611ed451afe8a4e2f7d0452ca4906',
  companies: {
    o: {
      stripePKey: 'pk_live_51J0D3pCzx9P8yVtSU9tLiHuUaMzFv8J71Wtu8GXUKG2VMx6Hp63Fnpdx8ig961FRIodjzM3KwypNxhhKXAYODDvQ00BeRwsnDr',
      auth0Domain: `${O}.auth0.com`,
      auth0ClientID: 'iAlx5e3dspTk5yRzIRROsqKK0iDOTEwh',
      auth0PayloadKey: `https://app.${O}.com`,
      colors: {
        main: '#031940'
      },
      payPalClientId: 'AX72N4ZdEcc8Cx2F_9gb-CM8Lq7Y2H8eu5qtT5dwIXrmG1_PjCPU5u5dDrX4fUnLYqGmchtv96MH3LX-',
    },
    t: {
      stripePKey: 'pk_live_51O7XTZI1ZC1cer64CsSnx40zuMumgk9eUV9vwkQfyhd7dvhpQF5aRN0hE3JjMM0cV4ow7BnBYO8EljgV1rGP3mIO00Hf6FTuuS',
      auth0Domain: `${T}.us.auth0.com`,
      auth0CustomDomain: `auth.${T}.com`,
      auth0ClientID: 'Km8rLr81biRxwjo4dXrRynm7AxBV86o2',
      auth0PayloadKey: `https://app.${T}.com`,
      colors: {
        main: '#1B6F7E'
      },
      payPalClientId: 'AQx9dZ9E5vDlxR0Ot6JN3coVbvT21uWSObUIUJdFvHtkVfwRoKvB7LbtWs10BiBXx77ne4trhol6E_Tw',
    }
  },
  hcaptchaSiteKey: 'c006edf3-2e6e-46a8-a3d4-b473077f84c2',
};
