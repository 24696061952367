import React from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, message } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

import { toArray, toTitleCase } from '../../utils/helper';
import GMBtypes from '../../shared/data/types.json';

const MAX_TAG_COUNT = 1;

const ButtonContainer = styled.div`
  position: absolute;
  top: 40px;
  display: flex;
`;

const StyledButton = styled.button`
  padding-left: 6px;
  text-decoration: underline;
  padding-right: 1px;
`;

const StyledContainer = styled.div`
  position: relative;
`;

export default function SelectType({ value, onChange, size, loading, types, placeholder = 'Category', showQucikButtons }) {
  const arrayValue = toArray(value);
  const selectedLength = arrayValue.length;
  const title = selectedLength > MAX_TAG_COUNT ? arrayValue.join(', ') : null;
  const isMaxValues = selectedLength > 100;

  function handleShowError() {
    message.error('The maximum amount of options selected');
  }

  return (
    <StyledContainer>
      <Tooltip
        placement='right'
        mouseEnterDelay={0.3}
        title={title}
      >
        <Select
          allowClear
          autoFocus
          showSearch
          maxTagCount={MAX_TAG_COUNT}
          mode='multiple'
          tokenSeparators={[',', '\n']}
          loading={loading}
          style={{ minWidth: '170px' }}
          size={size}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          optionFilterProp='children'
          {...(isMaxValues && { open: false, onDropdownVisibleChange: handleShowError })}
        >
          {(types || GMBtypes).map((r) => (
            <Option key={r} value={r}>{toTitleCase(r)}</Option>
          ))}
        </Select>
      </Tooltip>

      {showQucikButtons && <ButtonContainer>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 250))}
        >
          Top 250,
        </StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 500))}
        >
          500
        </StyledButton>
      </ButtonContainer>}
    </StyledContainer>
  );
}

SelectType.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  types: PropTypes.array,
  placeholder: PropTypes.string,
  showQucikButtons: PropTypes.bool,
};
