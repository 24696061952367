import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Collapse } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

import ResultsTypeSelect from './../ResultsTypeSelect';

const MIN_QUANTITY = 1;

const PriceLabel = styled.span`
  color: #58C560;
  font-size: 28px;
  font-weight: 800;
`;

const PriceDetailLabel = styled.span`
  font-size: 20px;
  padding-right: 8px;
`;

const StyledButtonText = styled.button`
  color: #262626;
  text-decoration: underline;
`;

const StyledButton = styled.button`
  color: #262626;
  font-size: 12px;
  text-decoration: underline;
`;

const StyledQuantityInput = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledSection = styled.div`
  margin-top: 20px;
`;

export default function ConfirmStage({ type, quantity, skip, loading, price, totalResults = 0, setType, onSetQuantity, setSkip, setPricesOpened }) {
  return <StyledSection>
    <Form style={{ fontSize: 24, margin: 'auto' }} labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout='horizontal'>
      <Form.Item label='Type' tooltip='File format. You will be able to change it later.'>
        <ResultsTypeSelect loading={loading} value={type} onChange={setType}/>
      </Form.Item>
      <Form.Item
        label='Quantity'
        tooltip={`Amount of the results to export. The maximum amount accordingly to your filters is ${totalResults ? totalResults.toLocaleString() : 'undefined'}.`}
      >
        <StyledQuantityInput>
          <InputNumber
            autoFocus
            style={{ width: 180, marginRight: 10 }}
            min={MIN_QUANTITY}
            max={type === 'xlsx' ? Math.min(totalResults, 1000000) : totalResults}
            step={1000}
            value={quantity}
            disabled={loading}
            onChange={(v) => onSetQuantity(v || MIN_QUANTITY)}
          />
          {totalResults && <>from&nbsp;
            <StyledButtonText type='button' className='link-button' onClick={() => onSetQuantity(totalResults)}>
              <strong><em>{totalResults.toLocaleString()}</em></strong>
            </StyledButtonText>&nbsp;available
          </>}
        </StyledQuantityInput>
        <div style={{ marginTop: 5 }}>
          {totalResults && totalResults !== quantity && (
            <StyledButton type='button' className='link-button' onClick={() => onSetQuantity(totalResults)}>
              All {totalResults.toLocaleString()}
            </StyledButton>
          )}
        </div>
      </Form.Item>
      <Collapse ghost style={{ marginTop: -30 }}>
        <Collapse.Panel forceRender header='Options' key='options'>
          <Form.Item label='Skip' tooltip='Amount of the results to skip'>
            <InputNumber
              autoFocus
              style={{ width: 180 }}
              max={totalResults - 1}
              min={0}
              step={100}
              value={skip}
              disabled={loading}
              onChange={(v) => setSkip(v || 0)}
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
      <StyledPriceWrapper>
        <strong>
          Total Price{' '}
          <QuestionCircleTwoTone
            style={{ fontSize: 18 }}
            title='Pricing'
            onClick={() => setPricesOpened(true)}
          />{' '}
          :
        </strong>{' '}
        <PriceDetailLabel>
          <PriceLabel>${price.toFixed(2).toLocaleString()}</PriceLabel>
        </PriceDetailLabel>
      </StyledPriceWrapper>
    </Form>
  </StyledSection>;
}

ConfirmStage.propTypes = {
  type: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  price: PropTypes.number.isRequired,
  totalResults: PropTypes.number,
  searchParams: PropTypes.object,
  setType: PropTypes.func.isRequired,
  onSetQuantity: PropTypes.func.isRequired,
  setSkip: PropTypes.func.isRequired,
  setPricesOpened: PropTypes.func.isRequired,
};
