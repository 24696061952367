import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Mixpanel from './analytics/mixpanel';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './redux/store';
import 'antd/dist/reset.css';
import 'leaflet/dist/leaflet.css';
import './i18n';

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
Mixpanel.init();
