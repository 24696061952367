import { SELECT_ITEM, DESELECT_ITEM } from '../actions/selectActions';

const initialState = {
  selectedItems: [],
};

export const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
  case SELECT_ITEM:
    return {
      ...state,
      selectedItems: [...state.selectedItems, action.payload],
    };

  case DESELECT_ITEM:
    return {
      ...state,
      selectedItems: state.selectedItems.filter((item) => item !== action.payload),
    };

  default:
    return state;
  }
};
