import React from 'react';
import PropTypes from 'prop-types';
import { Table, Empty, Checkbox, Button } from 'antd';
import PlaceCard from './Place/PlaceCard';
import { useSelector, useDispatch } from 'react-redux';
import { selectItem, deselectItem } from '../../redux/actions/selectActions';

const notFoundContent = <>
  <Empty description={<span>No results matching your filters. Please check your search terms and try again.</span>}>
    <br /><br />
    Commonly used queries:
    <br /><br />
    <a href='?cc=US&city=Palo Alto&limit=10&state=California&type=gift shop'>Gift shops in Palo Alto, USA</a><br />
    <a href='?cc=US&limit=10&state=New York&type=restaurant&type=bar'>Restaurants and bars in New York state, USA</a><br />
    <a href='?cc=US&city=san francisco&limit=10&state=california&type=real estate agency'>Real estate agencies in San Francisco, USA</a><br />
    <a href='?cc=CA&city=toronto&limit=10&type=clinic&type=doctor&type=therapists'>Clinics, doctors and therapists in Toronto, CA</a><br />
    <a href='?cc=GB&city=london&city=edinburgh&limit=10&type=gym'>Gyms in London and Edinburgh, GB</a><br />
    <br /><br /><br /><br /><br /><br />
  </Empty>
</>;

export default function PlacesTable({ places = [], total, pageSize, currentPage, onPagination, loading, onSearchParamsChange, onSelect }) {
  const selectedItems = useSelector((state) => state.items.selectedItems || []);
  const dispatch = useDispatch();

  const checkboxHandler = (e) => {
    if (!e || !e.target) return;
    const isSelected = e.target.checked;
    const value = e.target.value;

    if (isSelected) {
      dispatch(selectItem(value));
    } else {
      dispatch(deselectItem(value));
    }
  };

  const deselectAllHandler = () => {
    selectedItems.forEach(item => {
      dispatch(deselectItem(item));
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      width: 25,
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Checkbox
            onChange={checkboxHandler}
            value={record.os_id}
            checked={Array.isArray(selectedItems) && selectedItems.includes(record.os_id)}
          />
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (_, record) =>
        <PlaceCard value={record} key={record.display_name} onSearchParamsChange={onSearchParamsChange} />,
    }];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#262626' }}>
          Selected: {selectedItems.length}
        </span>
        <Button
          onClick={deselectAllHandler}
          type='link'
          disabled={selectedItems.length === 0}
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          Unselect all
        </Button>
      </div>
      <Table
        rowKey='os_id'
        locale={{ emptyText: notFoundContent }}
        scroll={{ y: '70vh' }}
        showHeader={false}
        loading={loading}
        columns={columns}
        dataSource={places.map(r => { r.key = r.id; return r; })}
        pagination={{
          pageSize,
          current: currentPage,
          total: total ? total : pageSize + 1,
          showSizeChanger: false,
          position: ['bottomRight']
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onSelect(rowIndex),
          };
        }}
        onChange={onPagination}
      />

    </div>
  );
}

PlacesTable.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
